import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Text, Image, Link, Icon } from 'components/atoms';

const TeamCard = ({ data, className, color }) => {
  if (!data || !data.node) {
    return null;
  }

  const { node } = data;
  const { title: name, acf, featured_media } = node;
  const { source_url, acf: placeholderBackground, media_details } =
    featured_media || {};
  const { position, linkedin_url, angellist_url } = acf;
  const { placeholder_base64 } = placeholderBackground || {};

  return (
    <div className={cn('team-card', [className])}>
      {featured_media && (
        <div className="team-card__image-container">
          <Image
            className="team-card__image"
            src={source_url}
            placeholder64={placeholder_base64}
            lazyLoading
            alt={name}
            imageFit={'cover'}
            image={media_details}
          />
        </div>
      )}

      <div className="team-card__description">
        <Text
          className="team-card__description__name headline-3"
          tag="p"
          color={cn({ 'transparent-green': color, 'dark-purple': !color })}
        >
          {name}
        </Text>
        <Text color={cn({ 'transparent-green': color, 'dark-purple': !color })}>
          {position}
        </Text>
      </div>

      <div className="team-card__social-media">
        {linkedin_url && (
          <Link
            className="team-card__social-media__link"
            href={linkedin_url}
            isRouter={false}
            target="_blank"
            rel="nofollow"
          >
            <Icon
              variant="linkedin"
              className={cn({
                'icon-linkedin_white': color,
              })}
            />
          </Link>
        )}

        {angellist_url && (
          <Link
            className="team-card__social-media__link"
            href={angellist_url}
            isRouter={false}
            target="_blank"
            rel="nofollow"
          >
            <Icon
              variant="angel"
              className={cn({
                'icon-angel_white': color,
              })}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

TeamCard.defaultProps = {
  className: '',
};

TeamCard.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default TeamCard;
