import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';
import TeamCard from 'components/molecules/TeamCard';
import TagsContainer from 'components/molecules/TagsContainer';

import { useAllTeamMember } from 'graphql/hooks';

import { windowSize } from 'utils/window';
import { htmlCheck } from 'utils/html';

const Team = ({ data, pageName, className }) => {
  const { css_id, caption, team_members, color_scheme } = data;
  const { title, subtitle } = caption;
  const { width } = windowSize;

  const allTeamMembers = useAllTeamMember();

  const profiles = team_members?.map(({ wordpress_id }) => {
    return allTeamMembers.find(
      member => member.node.wordpress_id === wordpress_id
    );
  });

  const isSingle = profiles?.length === 1;
  const isDouble = profiles?.length % 2 === 0;
  const isTriple = profiles?.length % 3 === 0;

  const isMobile = width < 1025;

  const offset = (isSingle || isDouble) && !isTriple ? 'offset-lg-1' : '';

  const darkTheme = color_scheme === 'dark';

  const isAbout = pageName === 'about';

  const teamProfile = profiles?.map((profile, profileId) => (
    <TeamCard data={profile} color={darkTheme} key={profileId} />
  ));

  const headerTitle = htmlCheck(title);
  const headerSubtitle = htmlCheck(subtitle);

  return (
    <Section
      className={cn('team', [`team_${color_scheme}`], [className])}
      id={css_id || data.id}
    >
      <Container>
        <Row>
          <Column
            col="12"
            md={isSingle ? '5' : '12'}
            lg="5"
            className={cn('team__header', [offset], {
              team__header_single: isSingle,
            })}
          >
            {headerTitle ? (
              <TagsContainer
                className={cn(
                  'tags-container_team',
                  'tags-container_team__header'
                )}
                isLight={darkTheme}
              >
                {title}
              </TagsContainer>
            ) : (
              <Text
                className={cn(
                  'tags-container_team',
                  isAbout
                    ? isMobile
                      ? 'headline-2'
                      : 'headline-1'
                    : 'headline-2'
                )}
                tag="h2"
                color={cn({
                  'transparent-green': darkTheme,
                  'dark-purple': !darkTheme,
                })}
              >
                {title}
              </Text>
            )}
            {headerSubtitle ? (
              <TagsContainer
                className={cn('tags-container_team', 'team__header__subtitle')}
                isLight={darkTheme}
              >
                {subtitle}
              </TagsContainer>
            ) : (
              <Text
                className="team__header__subtitle"
                color={cn({ 'transparent-green': darkTheme })}
              >
                {subtitle}
              </Text>
            )}
          </Column>

          <Column
            col="12"
            md={isSingle ? '5' : '12'}
            lg={isSingle ? '4' : offset ? '9' : '12'}
            className={cn(
              'team__profiles',
              { team__profiles__single: isSingle },
              { [offset]: !isSingle },
              { 'offset-md-1': isSingle },
              {
                team__profiles__even: isDouble && !isTriple,
              }
            )}
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
          >
            {teamProfile}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

Team.defaultProps = {
  className: '',
};

Team.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Team;
